﻿// h1 colors
.white-txt { h1 { color: $color-default; } }
.beige-txt { h1 { color: $color-beige; } }
.pink-txt { h1 { color: $color-pink; } }
.red-txt { h1 { color: $color-red; } }
.darkred-txt { h1 { color: $color-darkred; } }
.darkgreen-txt { h1 { color: $color-darkgreen; } }
.black-txt { h1 { color: $color-black; } }

// block colors
.white-bg {
	background: $color-default;
	color: $color-darkgreen;

	&.timeline-content {
		background: unset;

		img {
			background: $color-default;
		}

		.text-content {
			background: $color-default;
		}

		.overlay-btn {
			background: $color-darkgreen;

			&:after {
				color: $color-beige;
			}
		}

		.timeline-overlay {
			color: $color-darkgreen;
			background-color: $color-default;

			.overlay-close {
				color: $color-darkgreen;

				&:after {
					background: $color-default;
				}
			}
		}
	}

	&.info-block {
		h3:after {
			background: $color-darkgreen;
		}
	}

	&.parallax-inspiration-block {
		&:before {
			background-image: linear-gradient(to bottom, rgba($color-default, 0.25), rgba($color-default, 1)), url(../../images/inspiration-home-bg.jpg);
			background-size: cover;
		}

		&:after {
			background-image: linear-gradient(to bottom, rgba($color-default, 0.25), rgba($color-default, 1)), url(../../images/inspiration-leaf.jpg);
			background-size: cover;
		}
	}

	&.eyezoom-block {
		figure:after {
			background: $color-default;
		}
	}

	.button {
		color: $color-darkgreen;
		background-image: linear-gradient(to right, $color-red 50%, darken($color-red, 10%) 50%);

		&:after {
			color: $color-darkgreen;
		}
	}

	&.call-to-action {
		background: #f8cab3 !important;

		h2 {
			color: $color-red;
		}

		&:after {
			color: $color-lightpink;
		}
	}

	&.text-content {
		&:before {
			color: #ffc8ad;
		}
	}

	&.slider-block {
		h2 {
			color: $color-darkgreen !important;
		}

		.swiper-pagination-bullet {
			background: $color-darkgreen;

			&.swiper-pagination-bullet-active {
				background: $color-darkgreen;
			}
		}
	}

	&.info-block, &.counter-block, &.slider-block, &.call-to-action, &.media-zoom-block {
		&:before {
			background: url(../../images/pattern-grey.svg);
		}
	}
}

.beige-bg {
	background: $color-beige;
	color: $color-darkgreen;

	&.timeline-content {
		background: unset;

		img {
			background: $color-beige;
		}

		.text-content {
			background: $color-beige;
		}

		.overlay-btn {
			background: $color-darkgreen;

			&:after {
				color: $color-beige;
			}
		}

		.timeline-overlay {
			color: $color-darkgreen;
			background-color: $color-beige;

			.overlay-close {
				color: $color-darkgreen;

				&:after {
					background: $color-beige;
				}
			}
		}
	}

	&.info-block {
		h3:after {
			background: $color-darkgreen;
		}
	}

	&.parallax-inspiration-block {
		&:before {
			background-image: linear-gradient(to bottom, rgba($color-beige, 0.25), rgba($color-beige, 1)), url(../../images/inspiration-home-bg.jpg);
			background-size: cover;
		}

		&:after {
			background-image: linear-gradient(to bottom, rgba($color-beige, 0.25), rgba($color-beige, 1)), url(../../images/inspiration-leaf.jpg);
			background-size: cover;
		}
	}

	&.eyezoom-block {
		figure:after {
			background: $color-beige;
		}
	}

	.button {
		color: $color-darkgreen;
		background-image: linear-gradient(to right, $color-red 50%, darken($color-red, 10%) 50%);

		&:after {
			color: $color-darkgreen;
		}
	}

	&.call-to-action {
		background: #f8cab3 !important;

		h2 {
			color: $color-red;
		}

		&:after {
			color: $color-lightpink;
		}
	}

	&.text-content {
		&:before {
			color: #ffc8ad;
		}
	}

	&.slider-block {
		h2 {
			color: $color-red !important;
		}

		.swiper-pagination-bullet {
			background: $color-red;

			&.swiper-pagination-bullet-active {
				background: $color-red;
			}
		}
	}

	&.info-block, &.counter-block, &.slider-block, &.call-to-action, &.media-zoom-block {
		&:before {
			background: url(../../images/pattern-pink.svg);
		}
	}
}

.pink-bg {
	background: $color-pink;
	color: $color-red;

	&.timeline-content {
		background: unset;

		img {
			background: $color-pink;
		}

		.text-content {
			background: $color-pink;
		}

		.overlay-btn {
			background: $color-red;

			&:after {
				color: $color-pink;
			}
		}

		.timeline-overlay {
			color: $color-pink;
			background-color: $color-red;

			.overlay-close {
				background: $color-pink;

				&:after {
					color: $color-red;
				}
			}
		}
	}

	&.info-block {
		h3:after {
			background: $color-red;
		}
	}

	&.parallax-inspiration-block {
		&:before {
			background-image: linear-gradient(to bottom, rgba($color-pink, 0.25), rgba($color-pink, 1)), url(../../images/inspiration-home-bg.jpg);
			background-size: cover;
		}

		&:after {
			background-image: linear-gradient(to bottom, rgba($color-pink, 0.25), rgba($color-pink, 1)), url(../../images/inspiration-leaf.jpg);
			background-size: cover;
		}
	}

	&.eyezoom-block {
		&:before {
			background: $color-pink;
		}
	}

	.button {
		color: $color-pink;
		background-image: linear-gradient(to right, $color-red 50%, darken($color-red, 10%) 50%);

		&:after {
			color: $color-pink;
		}
	}

	&.call-to-action {
		background: #f8cab3 !important;

		&:before {
			background: url(../../images/pattern-pink.svg);
		}

		&:after {
			color: $color-lightpink;
		}
	}

	&.text-content {
		&:before {
			color: #ffc8ad;
		}
	}

	&.slider-block {
		h2 {
			color: $color-red !important;
		}

		color: $color-darkred;

		.swiper-pagination-bullet {
			background: $color-darkred;

			&.swiper-pagination-bullet-active {
				background: $color-darkred;
			}
		}

		.button {
			color: $color-darkgreen;
			background-image: linear-gradient(to right, $color-red 50%, darken($color-red, 10%) 50%);

			&:after {
				color: $color-darkgreen;
			}
		}
	}

	&.info-block, &.counter-block, &.slider-block, &.media-zoom-block {
		&:before {
			background: url(../../images/pattern-pink-alt.svg);
		}
	}
}

.red-bg {
	background: $color-red;
	color: $color-beige;

	&.timeline-content {
		background: unset;

		img {
			background: $color-red;
		}

		.text-content {
			background: $color-red;
		}

		.overlay-btn {
			background: $color-beige;

			&:after {
				color: $color-red;
			}
		}

		.timeline-overlay {
			color: $color-red;
			background-color: $color-beige;

			.overlay-close {
				background: $color-red;

				&:after {
					color: $color-beige;
				}
			}
		}
	}

	&.info-block {
		h3:after {
			background: $color-beige;
		}
	}

	&.parallax-inspiration-block {
		&:before {
			background-image: linear-gradient(to bottom, rgba($color-red, 0.25), rgba($color-red, 1)), url(../../images/inspiration-home-bg.jpg);
			background-size: cover;
		}

		&:after {
			background-image: linear-gradient(to bottom, rgba($color-red, 0.25), rgba($color-red, 1)), url(../../images/inspiration-leaf.jpg);
			background-size: cover;
		}
	}

	&.eyezoom-block {
		&:before {
			background: $color-red;
		}
	}

	.button {
		color: $color-red;
		background-image: linear-gradient(to right, $color-beige 50%, darken($color-beige, 10%) 50%);

		&:after {
			color: $color-red;
		}
	}

	&.call-to-action {
		&:after {
			color: #e86661;
		}
	}

	&.text-content {
		&:before {
			color: #e86661;
		}
	}

	&.slider-block {
		.swiper-pagination-bullet {
			background: $color-beige;

			&.swiper-pagination-bullet-active {
				background: $color-beige;
			}
		}
	}

	&.info-block, &.counter-block, &.slider-block, &.call-to-action, &.media-zoom-block {
		&:before {
			background: url(../../images/pattern-red.svg);
		}
	}
}

.darkred-bg {
	background: $color-darkred;
	color: $color-beige;

	&.timeline-content {
		background: unset;

		img {
			background: $color-darkred;
		}

		.text-content {
			background: $color-darkred;
		}

		.overlay-btn {
			background: $color-red;

			&:after {
				color: $color-beige;
			}
		}

		.timeline-overlay {
			color: $color-beige;
			background-color: $color-red;

			.overlay-close {
				background: $color-beige;

				&:after {
					color: $color-red;
				}
			}
		}
	}

	&.info-block {
		h3:after {
			background: $color-beige;
		}
	}

	&.parallax-inspiration-block {
		&:before {
			background-image: linear-gradient(to bottom, rgba($color-darkred, 0.25), rgba($color-darkred, 1)), url(../../images/inspiration-home-bg.jpg);
			background-size: cover;
		}

		&:after {
			background-image: linear-gradient(to bottom, rgba($color-darkred, 0.25), rgba($color-darkred, 1)), url(../../images/inspiration-leaf.jpg);
			background-size: cover;
		}
	}

	&.eyezoom-block {
		&:before {
			background: $color-darkred;
		}
	}

	.button {
		color: $color-darkred;
		background-image: linear-gradient(to right, $color-red 50%, darken($color-red, 10%) 50%);

		&:after {
			color: $color-darkred;
		}
	}

	&.call-to-action {
		&:after {
			color: #873b39;
		}
	}

	&.text-content {
		&:before {
			color: #873b39;
		}
	}

	&.slider-block {
		h2 {
			color: $color-red !important;
		}

		.swiper-pagination-bullet {
			background: $color-red;

			&.swiper-pagination-bullet-active {
				background: $color-red;
			}
		}
	}

	&.info-block, &.counter-block, &.slider-block, &.call-to-action, &.media-zoom-block {
		&:before {
			background: url(../../images/pattern-darkred.svg);
		}
	}
}

.darkgreen-bg {
	background: $color-darkgreen;
	color: $color-beige;

	&.timeline-content {
		background: unset;

		img {
			background: $color-darkgreen;
		}

		.text-content {
			background: $color-darkgreen;
		}

		.overlay-btn {
			background: $color-beige;

			&:after {
				color: $color-darkgreen;
			}
		}

		.timeline-overlay {
			color: $color-darkgreen;
			background-color: $color-beige;

			.overlay-close {
				background: $color-darkgreen;

				&:after {
					color: $color-beige;
				}
			}
		}
	}

	&.info-block {
		h3:after {
			background: $color-beige;
		}
	}

	&.parallax-inspiration-block {
		&:before {
			background-image: linear-gradient(to bottom, rgba($color-darkgreen, 0.25), rgba($color-darkgreen, 1)), url(../../images/inspiration-home-bg.jpg);
			background-size: cover;
		}

		&:after {
			background-image: linear-gradient(to bottom, rgba($color-darkgreen, 0.25), rgba($color-darkgreen, 1)), url(../../images/inspiration-leaf.jpg);
			background-size: cover;
		}
	}

	&.eyezoom-block {
		&:before {
			background: $color-darkgreen;
		}
	}

	.button {
		color: $color-darkgreen;
		background-image: linear-gradient(to right, $color-red 50%, darken($color-red, 10%) 50%);

		&:after {
			color: $color-darkgreen;
		}
	}

	&.call-to-action {
		&:after {
			color: #0d3a3a;
		}
	}

	&.text-content {
		&:before {
			color: #0d3a3a;
		}
	}

	&.slider-block {
		.swiper-pagination-bullet {
			background: $color-beige;

			&.swiper-pagination-bullet-active {
				background: $color-beige;
			}
		}
	}

	&.info-block, &.counter-block, &.slider-block, &.call-to-action, &.media-zoom-block {
		&:before {
			background: url(../../images/pattern-darkgreen.svg);
		}
	}
}

.black-bg {
	background: $color-black;
	color: $color-beige;

	.caption, p {
		color: #999999;
	}

	&.timeline-content {
		background: unset;

		img {
			background: $color-black;
		}

		.text-content {
			background: $color-black;
		}

		.overlay-btn {
			background: $color-beige;

			&:after {
				color: $color-black;
			}
		}

		.timeline-overlay {
			color: $color-black;
			background-color: $color-beige;

			.overlay-close {
				background: $color-black;

				&:after {
					color: $color-beige;
				}
			}
		}
	}

	&.info-block {
		h3:after {
			background: $color-beige;
		}
	}

	&.parallax-inspiration-block {
		&:before {
			background-image: linear-gradient(to bottom, rgba($color-black, 0.25), rgba($color-black, 1)), url(../../images/inspiration-home-bg.jpg);
			background-size: cover;
		}

		&:after {
			background-image: linear-gradient(to bottom, rgba($color-black, 0.25), rgba($color-black, 1)), url(../../images/inspiration-leaf.jpg);
			background-size: cover;
		}
	}

	&.eyezoom-block {
		&:before {
			background: $color-black;
		}
	}

	.button {
		color: $color-black;
		background-image: linear-gradient(to right, $color-red 50%, darken($color-red, 10%) 50%);

		&:after {
			color: $color-black;
		}
	}

	&.call-to-action {
		&:after {
			color: #0d3a3a;
		}
	}

	&.text-content {
		&:before {
			color: #0d3a3a;
		}
	}

	&.slider-block {
		.swiper-pagination-bullet {
			background: $color-beige;

			&.swiper-pagination-bullet-active {
				background: $color-beige;
			}
		}
	}

	&.info-block, &.counter-block, &.slider-block, &.call-to-action, &.media-zoom-block {
		&:before {
			background: url(../../images/pattern-black.svg);
		}
	}
}
