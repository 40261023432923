﻿header {
	&.headroom--not-top {
		nav {
			&:before {
				transition: all .15s ease-in-out;
				opacity: 0;
			}
			&:after {
				transition: all .15s ease-in-out;
				opacity: 1;
			}
		}
	}
}

nav {
	position: fixed;
	width: 100%;
	top: 0;
	display: flex;
	justify-content: center;
	z-index: 99;

	&:before {
		position: absolute;
		content: "";
		width: 100vw;
		top: 0;
		left: 0;
		background-image: -moz-linear-gradient( 0, rgba(0,0,0,0) 0%, rgb(0,0,0) 100%);
		background-image: -webkit-linear-gradient( 0, rgba(0,0,0,0) 0%, rgb(0,0,0) 100%);
		background-image: -ms-linear-gradient( 0, rgba(0,0,0,0) 0%, rgb(0,0,0) 100%);
		background-image: linear-gradient( 0, rgba(0,0,0,0) 0%, rgb(0,0,0) 100%);
		transition: all .15s ease-in-out;
		height: rem(160px);
		z-index: 1;
		opacity: .5;
		pointer-events: none;
	}

	&:after {
		position: absolute;
		content: "";
		width: 100vw;
		top: 0;
		left: 0;
		height: rem(96px);
		background: rgba(0, 0, 0, .4);
		transition: all .15s ease-in-out;
		opacity: 0;
		pointer-events: none;
	}

	.webshop-link, .floraxchange-link {
		position: absolute;
		top: rem(36px);
		width: rem(24px);
		height: rem(24px);
		z-index: 1;

		i:after {
			position: absolute;
			font-size: rem(24px);
			color: $color-beige;
			transition: color .15s ease;
		}

		@media(hover: hover) and (pointer: fine) {
			&:hover {
				i:after {
					transition: color .15s ease;
					color: $color-red;
				}
			}
		}
	}

	.webshop-link {
		right: rem(72px);
		transition-delay: 300ms;
		z-index: 10;

		i:after {
			@include icon($icon-cart);
		}
	}

	.floraxchange-link {
		right: rem(24px);
		transition-delay: 300ms;
		z-index: 10;

		i:after {
			@include icon($icon-exchange);
		}
	}

	.navbar {
		padding: 0 rem(120px);
		max-width: calc(87rem + 240px);
		width: 100%;
		height: rem(48px);
		margin: rem(24px) auto 0 auto;
		z-index: 1;
		display: flex;
		flex-wrap: nowrap;
		transition-delay: 275ms;

		@media only screen and (max-width: 1600px) {
			.logo {
				margin-left: rem(-96px);
			}
		}

		@media only screen and (min-width: 1921px) {
			max-width: calc(72.5vw + 120px);
		}

		.header-nav {
			width: fit-content;
			margin-top: rem(12px);
			margin-left: auto;
			display: none;

			@include breakpoint(l) {
				display: flex;
			}

			li {
				&.active {
					> a {
						color: $color-red;
					}
				}
			}

			> li {
				font-size: rem(18px);
				font-weight: 700;
				margin-right: rem(29.6px);
				z-index: 2;

				a {
					position: relative;
					display: block;
					overflow: visible;
					width: fit-content;
					text-shadow: 2px 2px rgb(0 0 0 / 15%);

					@media(hover: hover) and (pointer: fine) {
						&:hover {
							&:after {
								transition: all .25s ease-out;
								transform: scaleX(100%);
							}
						}
					}

					&:after {
						position: absolute;
						content: "";
						transform: scaleX(0);
						width: 100%;
						height: 2px;
						bottom: -2px;
						left: 0;
						background: $color-beige;
						transition: all .25s ease-out;
					}
				}

				&.dropdown {
					ul {
						opacity: 0;
						position: absolute;
						pointer-events: none;
						transition: all 0.15s ease;
						padding-top: rem(8px);
						background: $color-darkgreen;
						background-size: 120% 100%;
						padding: rem(50px) rem(21px) rem(4px);
						margin-top: rem(-36px);
						margin-left: rem(-16px);
						border-radius: rem(12px);
						z-index: -1;

						&.open {
							opacity: 1;
							pointer-events: all;
							transition: all 0.15s ease;
						}

						li {
							font-weight: 500;
							padding-bottom: rem(14px);
						}
					}
				}
			}
		}
	}
}
