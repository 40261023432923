﻿.logo {
	position: absolute;
	width: rem(172px);
	height: rem(48px);
	text-indent: -99999px;
	display: flex;
	background-size: contain;
	overflow: hidden;
	background-repeat: no-repeat;
	z-index: 1;
	background-image: url(../../images/logo.svg);
}
