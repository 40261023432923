﻿section {
	position: relative;

	&.alternating-inspiration-block {
		@include font-alternating-inspiration-paragraph;
		background: #0c0a0b;
		z-index: 1;
		//padding-bottom: rem(1280px);

		&:before {
			/*position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: "";
			background: url(../../images/dots-pattern.jpg) repeat;*/
		}

		p {
			margin-bottom: rem(10px);
		}

		h3 {
			@include font-heading-inspiration;
			margin-bottom: rem(24px);

			@include breakpoint-max(lm) {
				font-size: rem(48px) !important;
				line-height: rem(48px) !important;
			}
		}

		.text-column {
			transform: translateY(75px);
			opacity: 0;

			@include breakpoint(l) {
				//transform: translateY(200px);
			}
		}

		.text-column p:last-child {
			margin-bottom: unset !important;
		}

		.container {
			padding-top: unset;
			padding-bottom: unset;

			@include breakpoint-max(l) {
				margin-bottom: rem(48px);
			}

			&.block-1 {
				@include breakpoint-max(l) {
					.columns {
						flex-direction: column-reverse;
					}
				}

				.text-column {
					padding-bottom: rem(24px);

					@include breakpoint(l) {
						padding: rem(120px) 0 0 rem(144px);
					}
				}

				.media-column {
					z-index: 1;
				}
			}

			&.block-2 {
				.text-column {
					padding-bottom: rem(48px);

					@include breakpoint(l) {
						padding: rem(120px) rem(120px) 0 rem(76px);
					}
				}

				.media-column {
					@include breakpoint(l) {
						margin-top: rem(-120px);
					}
				}
			}

			&.block-3 {
				.media-column {
					@include breakpoint(l) {
						margin-top: rem(-120px);
					}

					z-index: 1;
				}
			}

			&.block-4 {
				.text-column {
					padding-bottom: rem(24px);

					@include breakpoint(l) {
						padding: rem(120px) rem(120px) 0 rem(76px);
					}
				}

				.media-column {
					@include breakpoint(l) {
						margin-top: rem(-200px);
					}
				}
			}

			&.block-5 {
				.text-column {
					@include breakpoint(l) {
						padding: rem(120px) 0 0 rem(170px);
					}
				}
			}
		}

		.container:first-child {
			padding-top: rem(74px);

			@include breakpoint(l) {
				padding-top: rem(200px);
			}
		}

		.container:last-child {
			margin-bottom: unset !important;
			padding-bottom: rem(74px);

			@include breakpoint(l) {
				padding-bottom: rem(200px);
			}
		}
	}

	&.alternating-inspiration-block + section {
		h2 {
			@include font-heading-inspiration;

			@include breakpoint-max(lm) {
				font-size: rem(48px) !important;
				line-height: rem(48px) !important;
			}
		}
	}

	&.content-fade-block {
		figure {
			position: relative;
			transform: translateY(0) scale(1.5);
			min-height: 100vh;

			&:after {
				position: absolute;
				content: "";
				background-image: linear-gradient( 0, rgb(0,48,48) 0%, rgba(0,48,48,0) 100%);
				background-image: -moz-linear-gradient( 0, rgb(0,48,48) 0%, rgba(0,48,48,0) 100%);
				background-image: -webkit-linear-gradient( 0, rgb(0,48,48) 0%, rgba(0,48,48,0) 100%);
				background-image: -ms-linear-gradient( 0, rgb(0,48,48) 0%, rgba(0,48,48,0) 100%);
				width: 100%;
				height: 44%;
				bottom: 0;
				left: 0;
				pointer-events: none;
			}

			figcaption {
				position: absolute;
				z-index: 1;
				opacity: 0;
				left: rem(24px);
				bottom: rem(96px);
				transform: translateY(100px);

				@include breakpoint(l) {
					max-width: 42vw;
					left: rem(124px);
					bottom: rem(200px);
				}

				h2, span {
					text-align: left;
				}
			}
		}
	}

	&.eyezoom-block {
		.eye-mask-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			z-index: 1;

			.eye-mask {
				width: 100%;
				height: 100%;
				background: url(../../images/eye-mask.svg) no-repeat;
				background-size: cover;
				background-position: center center;
			}
		}

		figure {
			width: 100%;
			height: 100vh;
		}
	}

	&.media-fade-block {
		background: #0c0a0b;

		figure {
			min-height: 100vh;
			opacity: 0;
			transform: scale(1.1);

			video {
				opacity: 0;
			}
		}
	}

	&.media-zoom-block {
		&:before {
			position: absolute;
			content: "";
			width: rem(200px);
			height: 100%;
			right: rem(-80px);
			top: 0;
			z-index: 0;
		}

		figure {
			min-height: 100vh;

			figcaption {
				span {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					display: block;
					color: $color-beige;
					font-size: rem(40px);
					font-weight: 600;
					line-height: rem(80px);

					@include breakpoint(l) {
						font-size: rem(72px);
					}
				}

				span:not(:first-child) {
					opacity: 0;
				}
			}
		}
	}

	&.parallax-inspiration-block {
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			width: 100%;
			height: rem(1356px);
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			width: 100%;
			height: rem(1280px);
			opacity: .2;
		}

		.container {

			.left-parallax-container {
				position: absolute;
				bottom: 21.8%;
				width: rem(408px);
				left: 0;
				display: none;

				@include breakpoint(l) {
					display: block;
				}
			}

			.right-parallax-container {
				position: absolute;
				top: rem(650px);
				width: rem(665px);
				right: 0;
				display: none;

				@include breakpoint(l) {
					display: block;
				}
			}

			padding-bottom: rem(160px);

			@include breakpoint(lm) {
				padding-bottom: rem(512px);
			}

			&:before {
				position: absolute;
				bottom: 0;
				left: 0;
				content: "";
				background: url(../../images/inspiration-tiles.jpg) no-repeat;
				background-size: cover;
				width: 100%;
				height: rem(432px);

				@include breakpoint (lm) {
					height: rem(787px);
				}
			}
		}

		.column {
			//transform: translateY(150px);
			opacity: 0.5;
			z-index: 1;
		}
	}

	&.parallax-intro-block {
		z-index: 1;

		.intro-parallax-container {
			position: absolute;
			height: 100%;
			top: rem(160px);
			width: rem(240px);
			right: rem(-130px);
			display: none;

			@include breakpoint(lm) {
				display: block;
			}

			@include breakpoint(l) {
				width: rem(400px);
				right: rem(50px);
			}

			figure {
				position: absolute;
				width: 100%;
				height: 100%;

				&.gsap-translate-down-slow, &.gsap-translate-down {
					top: rem(-250px);
				}
			}
		}

		.container {
			@include breakpoint(l) {
				padding-top: rem(164px);
				padding-bottom: rem(164px);
			}

			p {
				@include font-intro-home;
			}

			p:last-child {
				margin-bottom: 0;
			}

			.columns {

				@include breakpoint-max(l) {
					flex-direction: column-reverse;
				}

				.column:first-child {
					figure {
						mask: url(../../images/eye.svg) no-repeat;
						mask-position: center center;

						@include breakpoint(l) {
							mask-position-x: rem(21px);
							-webkit-mask-position-x: rem(21px);
						}
					}
				}

				.column:last-child {
					margin-bottom: rem(48px);

					@include breakpoint(l) {
						margin-bottom: unset;
						padding-left: rem(24px);
						max-width: rem(480px);
						display: flex;
						flex-direction: column;
						justify-content: center;
					}
				}
			}
		}
	}

	&.parallax-product-block {
		.container {
			padding-top: unset;

			.columns {
				.column:first-child {
					display: flex;
					flex-direction: column;
					justify-content: center;

					h2 {
						@include font-product-home;
						text-align: left;
						margin-bottom: rem(24px);
					}

					p {
						@include font-product-home-text;
					}

					@include breakpoint(l) {
						padding: 0 rem(7.5vw);
					}
				}

				.product-parallax-container {
					position: relative;
					width: 100%;
					height: rem(812px);

					figure {
						//transform: translateY(200px);
						position: absolute;
						width: 100%;
						height: 100%;

						img {
							object-fit: contain;
						}

						&.gsap-translate-down-slow, &.gsap-translate-down {
							top: rem(-100px);
						}

						&.gsap-translate-up {
							top: rem(200px);
						}
					}
				}
			}
		}
	}

	&.side-scrolling-block {
		padding-bottom: rem(160px);

		.text {
			position: relative;
			font-size: rem(20px);
			line-height: rem(32px);
			font-weight: 300;
			max-width: rem(496px);
			color: $color-darkgreen;
			margin: 0 auto;
			padding: rem(194px) rem(32px);

			p:last-child {
				margin-bottom: unset;
			}

			&:before {
				@include icon($icon-watermark);
				position: absolute;
				font-size: rem(50vw);
				left: 50%;
				bottom: -25vw;
				transform: translateX(-50%);
				z-index: 0;
				opacity: .05;
				color: $color-darkgreen;
			}
		}

		.multimedia {
			padding-top: unset !important;
			padding-bottom: unset !important;

			@include breakpoint-max(l) {
				max-width: unset;
				width: 100%;
			}

			.wrapper {
				display: flex;

				.item {
					flex-shrink: 0;
					width: 100%;
					height: 100%;

					figure {
						//min-height: 96vh;
					}
				}
			}
		}
	}
}
