﻿section {
	position: relative;

	&.inspiration-block {
		h2 {
			margin-bottom: rem(58px);
		}

		&.text-inspiration {
			.column {
				@media(hover: hover) and (pointer: fine) {
					&:hover {
						//transform: scale(1.025) !important;
						//transition-duration: .3s;
					}
				}
			}
		}

		.column {
			margin-bottom: rem(48px);
			transition-duration: .3s;

			@include breakpoint(l) {
				margin-bottom: rem(96px);
			}

			&.text-column .content {
				position: relative;
				height: 100%;
				padding: rem(32px) rem(32px);
				display: flex;
				flex-direction: column;
				justify-content: center;
				background: #f8cab3;

				.text {
					@include font-inspiration;
				}

				@include breakpoint(l) {
					padding: 10% 17.8%;
					height: calc(100% - 96px);
				}

				&:before {
					position: absolute;
					width: 100%;
					height: 100%;
					content: "";
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					opacity: .2;
					background: url(../../images/inspiration-bg.jpg) no-repeat;
					background-position: 81%;
					background-size: cover;
				}
			}
		}

		.container {
			padding-bottom: rem(26px);
		}

		@include breakpoint(l) {
			.container {
				padding-top: rem(192px);
				padding-bottom: rem(96px);
			}

			.is-50-l.offset {
				margin-top: rem(96px);
			}

			.is-50-l:first-of-type {
				//margin-bottom: rem(194px);
			}
		}
	}

	&.text-block {
		h2 {
			margin-bottom: rem(68px);
		}

		@include breakpoint(l) {
			h2 {
				margin-bottom: rem(88px);
			}

			.extra-column {
				//padding-left: rem(72px);
				padding-left: rem(20px);
				padding-right: rem(53.6px);
			}

			.extra-column-padding {
				padding-right: rem(72.8px);
			}

			.container {
				padding-top: rem(208px);
				padding-bottom: rem(192px);
			}
		}
	}

	&.big-media-block {
		p {
			text-align: center;
		}

		h2 {
			margin-bottom: rem(24px);
		}

		@include breakpoint(l) {
			.container {
				padding-top: rem(208px);
				padding-bottom: rem(192px);
			}
		}
	}

	&.call-to-action {
		height: auto;
		display: flex;
		align-items: center;
		overflow: hidden;

		h2 {
			@include breakpoint(l) {
				text-align: left;
			}
		}

		.container {
			padding-top: rem(120px);
			padding-bottom: rem(120px);
			z-index: 1;
		}

		@include breakpoint(l) {
			height: 100vh;
		}

		&:after {
			@include icon($icon-watermark);
			position: absolute;
			font-size: rem(80vw);
			left: 50%;
			top: 70%;
			transform: translate(-50%, -50%);
			z-index: 0;

			@include breakpoint(l) {
				font-size: rem(75vw);
				top: 67%;
			}

			@include breakpoint(ll) {
				font-size: rem(58vw);
			}
		}

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			width: 100%;
			height: 100%;
			background-size: 18% !important;

			@include breakpoint(l) {
				background-size: 11% !important;
			}
		}
	}

	&.timeline-block {
		@media only screen and (max-width: 1732px) {
			padding-bottom: rem(48px);
		}

		.container {
			position: relative;

			@include breakpoint(l) {
				padding-top: rem(192px);
				padding-bottom: rem(192px);
			}
		}

		.timeline-heading {
			margin-bottom: rem(140px);

			.text {
				p:last-child {
					margin-bottom: 0;
					text-align: center;
				}
			}
		}

		h2 {
			margin-bottom: rem(24px);
		}

		.timeline-line, .timeline-line-before {
			position: absolute;
			left: rem(-232px);
			transform: translateX(-50%);
		}

		.timeline-line {
			z-index: 2;
			//margin-top: rem(10px);
			padding-left: 1px;
		}

		.timeline-years {
			position: absolute;
			margin-top: 25vw;
			left: rem(-232px);
			transform: translateX(-50%);
			z-index: 1;

			.timeline-year {
				position: absolute;
				left: rem(-150px);
				font-weight: 600;
				font-size: rem(36px);

				&:before {
					position: absolute;
					left: rem(150px);
					transform: translateX(-50%);
					border-radius: 50%;
					content: "";
					width: rem(32px);
					height: rem(32px);
					background: $color-beige;
				}

				&:after {
					position: absolute;
					top: rem(16px);
					left: rem(150px);
					transform: translate(-50%,-50%);
					border-radius: 50%;
					content: "";
					width: rem(16px);
					height: rem(16px);
					transition: all .15s ease;
				}
			}

			.timeline-year:first-child, .timeline-year.circled-red {
				&:after {
					background: $color-red;
					transition: all .15s ease;
				}
			}
		}

		.timeline-line, .timeline-line-before, .timeline-years {
			display: none;

			@media only screen and (min-width: 1733px) {
				display: block;
			}
		}

		.timeline-container {
			position: relative;

			.pin-spacer {
				left: 0 !important;
			}

			.timeline {
				width: 100%;
				height: calc(98.2vh - 80px);
				position: relative;
				overflow: visible;
				//transform: translateX(0) !important;
				//margin-top: rem(160px) !important;
				//top: 50% !important;
				//left: 50% !important;
				//transform: translate(-50%, -50%) !important;

				.vertical-progress-bar {
					position: absolute;
					bottom: rem(-16px);
					left: 0;
					content: "";
					width: 100%;
					height: rem(8px);
					background-color: $color-beige;
					display: none;

					@media only screen and (max-width: 1732px) {
						display: block;
					}

					.inner-progress-bar {
						transform-origin: 0 0;
						width: 1%;
						height: 100%;
						background-color: $color-red;
					}
				}

				.timeline-item {
					.timeline-content {
						position: absolute;
						width: 100%;
						height: 100%;
						top: 50%;
						transform: translateY(-50%);
						opacity: 0;
						display: flex;
						flex-direction: column;
						justify-content: center;

						.timeline-year {
							font-weight: 700;
							margin-bottom: 0.5rem;

							@include breakpoint(l) {
								display: none;
							}
						}

						h3, h4 {
							font-size: rem(36px);
							line-height: rem(40px);
						}

						&.overlay-trigger {
							cursor: pointer;

							@media(hover: hover) and (pointer: fine) {
								&:hover {
									.overlay-btn, .overlay-close {
										transform: scale(1.15) !important;
										transition-duration: .3s !important;
									}
								}
							}
						}

						.text-content {
							position: relative;
							display: flex;
							flex-direction: column;
							justify-content: center;
							text-align: center;
							padding: rem(48px) rem(28px);
							z-index: 1;

							@include breakpoint(l) {
								padding: rem(96px) rem(125px);
							}

							p:last-of-type {
								margin-bottom: unset !important;
							}

							.overlay-btn {
								position: relative;
								display: block;
								width: 48px !important;
								height: 48px !important;
								border-radius: 50%;
								margin: rem(21px) auto 0 auto;
								z-index: 1;
								transition: all .15s ease;

								&:after {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									content: "+";
									font-size: rem(24px);
									font-weight: 600;
								}
							}

							.text {
								li {
									width: auto;
									text-align: left;
								}
							}
						}

						.timeline-overlay {
							position: absolute;
							width: 100%;
							height: 100%;
							z-index: 2;
							text-align: center;
							padding: rem(32px) 10%;
							display: flex;
							flex-direction: column;
							justify-content: center;
							opacity: 0;
							pointer-events: none;
							transition: opacity .15s ease;

							.overlay-close {
								position: absolute;
								width: rem(48px);
								height: rem(48px);
								content: "";
								cursor: pointer;
								top: rem(24px);
								right: rem(24px);
								border-radius: 50%;
								transition: all .15s ease;

								&:after {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									content: "x";
									font-size: rem(24px);
									font-weight: 600;
									margin-top: -1px;
								}
							}

							&.open {
								opacity: 1;
								pointer-events: all;
								transition: opacity .15s ease;
							}

							h4 {
								margin-bottom: rem(16px);
							}
						}
					}
				}

				.timeline-item:first-of-type {
					.timeline-content:first-of-type {
						pointer-events: all;
					}
				}

				.timeline-content:not(first-of-type) {
					pointer-events: none;
				}

				.timeline-item {
					//height: 100%;
					//display: flex;
					//align-items: center;
					//justify-content: center;
				}

				.timeline-item:first-child {
					.timeline-content:first-child {
						opacity: 1;
						z-index: -1;
					}
				}
			}
		}
	}

	&.shortcut-block {
		h2 {
			margin: 0 auto rem(58px);

			@include breakpoint(l) {
				margin: 0 auto rem(64px);
				max-width: rem(912px);
			}
		}

		h3 {
			font-size: rem(36px);
			line-height: rem(40px);
		}

		.column {
			margin-bottom: rem(48px) !important;
			transition-duration: .3s;

			@media(hover: hover) and (pointer: fine) {
				&:hover {
					//transform: scale(1.025) !important;
					//transition-duration: .3s !important;
				}
			}

			@include breakpoint(l) {
				margin-bottom: rem(96px) !important;
			}
		}

		.column:last-child {
			margin-bottom: unset !important;
		}

		@include breakpoint(l) {
			.column:nth-child(2) {
				margin-top: rem(96px);
			}

			.container {
				padding-top: rem(192px);
				padding-bottom: rem(192px);
			}
		}

		.wrapper {
			display: block;
		}

		.text-content {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			overflow: hidden !important;
			padding: rem(48px) rem(28px);

			@include breakpoint(l) {
				padding: rem(89px) rem(94px);
			}

			z-index: 1;

			p:last-child {
				margin-bottom: rem(32px);
			}

			&:before {
				@include icon($icon-watermark);
				position: absolute;
				font-size: rem(350px);
				right: rem(-64px);
				bottom: rem(-64px);
				z-index: -1;
			}
		}
	}

	&.vertical-media-slider {
		position: relative;

		.swiper-slide {
			width: 100%;
			height: auto;

			figure {
				height: 100%;
			}
		}

		.portrait-slide {
			max-width: 30.375rem;
		}

		.landscape-slide {
			max-width: 54rem;
		}
	}

	&.slider-block {
		position: relative;

		&:before {
			position: absolute;
			content: "";
			width: 100%;
			height: rem(173px);
			left: 0;
			bottom: 0;
			z-index: 0;
		}

		figure {
			height: 100%;
		}

		.column:first-child {
			padding: rem(74px) rem(48px) rem(74px) rem(24px);

			@include breakpoint(l) {
				padding: rem(64px);
			}

			@include breakpoint(ll) {
				padding: rem(120px);
			}

			display: flex;
			flex-direction: column;
			justify-content: center;

			.caption, h2 {
				text-align: left !important;
			}

			h2 {
				margin-bottom: rem(24px);
			}

			p:last-child {
				margin-bottom: 0;
			}
		}

		.swiper-slide {
			margin-top: auto;
			width: 100% !important;

			.button {
				margin-top: rem(28px);
			}

			.column:first-child {
				opacity: 0;
				transform: translateY(-100%) !important;
				transition: .75s ease;
			}
		}

		.swiper-slide-next {
			.column:first-child {
				transform: translateY(100%) !important;
			}
		}

		.swiper-slide-prev {
			.column:first-child {
				transform: translateY(-100%) !important;
			}
		}

		.swiper-slide-active {
			.column:first-child {
				opacity: 1;
				transform: translateY(0) !important;
				transition: .75s ease !important;
			}
		}
	}

	&.info-block, &.counter-block {
		&:before {
			position: absolute;
			content: "";
			width: rem(200px);
			height: 100%;
			right: rem(-80px);
			top: 0;
			z-index: 0;
		}

		@include breakpoint(l) {
			.container {
				padding-top: rem(192px);
				padding-bottom: rem(192px);
			}
		}

		.column {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			figure {
				width: rem(96px);
				margin-bottom: rem(48px);
			}

			p:last-child {
				margin-bottom: 0;
			}

			margin-bottom: rem(98px);

			@include breakpoint(l) {
				margin-bottom: rem(180px);
			}
		}

		.icon figure {
			margin-left: auto;
			margin-right: auto;
			overflow: visible;
		}
	}

	&.counter-block {
		h2 {
			font-weight: 600;
			font-size: rem(72px);
			line-height: rem(48px);
			margin-bottom: rem(12px);

			@include breakpoint(l) {
				margin-bottom: rem(21px);
			}
		}

		.column {
			width: 70%;
			margin: 0 auto rem(98px) auto;

			&:last-child {
				margin-bottom: unset !important;
			}
		}

		.counter {
			> span {
				font-weight: 600;
				font-size: rem(32px);
				line-height: rem(40px);

				@include breakpoint(l) {
					font-size: rem(36px);
					line-height: rem(48px);
				}
			}
		}
	}

	&.info-block {
		.column {
			margin-bottom: rem(48px) !important;
		}

		h2 {
			margin-bottom: rem(64px) !important;

			@include breakpoint(l) {
				margin-bottom: rem(96px) !important;
			}
		}

		.tab-buttons {
			@include breakpoint(l) {
				max-width: 80%;
				margin: 0 auto;
			}

			.column {
				margin-bottom: rem(64px);
			}

			h3 {
				position: relative;
				display: block;
				width: fit-content;
				font-size: rem(36px);
				line-height: rem(24px);
				cursor: pointer;

				&:after {
					position: absolute;
					bottom: rem(-20px);
					left: 0;
					content: "";
					width: 100%;
					height: 4px;
					opacity: 0;
					transition: .15s ease;
				}

				&.is-active {
					&:after {
						opacity: 1;
						transition: .15s ease;
					}
				}
			}
		}

		.text {
			margin-bottom: rem(64px);

			@include breakpoint(l) {
				max-width: 74%;
				margin: 0 auto rem(64px) auto;
			}

			p:last-child {
				margin-bottom: 0;
			}
		}

		.tab {
			display: none;

			&.is-active {
				display: block;
			}

			.column {
				font-weight: 300;
				font-size: 1rem;
				line-height: 1.5rem;

				&:last-child {
					margin-bottom: unset !important;
				}
			}
		}
	}
}
