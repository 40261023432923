﻿.image {

	&.is-square {
		@include responsive-container-psudo(1, 1);
	}

	&.is-4by3 {
		@include responsive-container-psudo(4, 3);
	}

	&.is-portrait {
		@include responsive-container-psudo(0.75, 1);
	}

	&.is-3by2 {
		@include responsive-container-psudo(3, 2);
	}

	&.is-2by1 {
		@include responsive-container-psudo(2, 1);
	}

	&.is-16by9 {
		@include responsive-container-psudo(16, 9);
	}

	&.is-067by1 {
		@include responsive-container-psudo(0.67, 1);
	}

	&.is-137by1 {
		@include responsive-container-psudo(1.37, 1);
	}

	&.is-media-fade {
		@include responsive-container-psudo(1.39, 1);
	}

	&.is-intro-eye {
		@include responsive-container-psudo(1.38, 1);
	}

	&.is-intro-parallax {
		@include responsive-container-psudo(0.42, 1);
	}

	&.is-product-parallax {
		@include responsive-container-psudo(1.03, 1);
	}

	&.is-left-parallax {
		@include responsive-container-psudo(0.51, 1);
	}

	&.is-right-parallax {
		@include responsive-container-psudo(0.76, 1);
	}

	img, &div, video {
		width: 100%;
		height: auto;
	}

	.video-button {
		&:before {
			animation: pulse 1500ms infinite;
		}

		transition: all .15s ease-in-out;
	}

	@media(hover: hover) and (pointer: fine) {
		&:hover {
			.video-button {
				transform: translate(-50%,-50%) scale(1.15);
				transition: all .15s ease-in-out;
			}
		}
	}
}
