﻿@import '~aos/src/sass/core';
@import '~aos/src/sass/easing';

@media screen and (max-width: 1023px) {
	.js {
		$aos-distance: 25px;
		// Only use AOS when JavaSript is activated
		@import '~aos/src/sass/animations';
	}
}

@media screen and (min-width: 1024px) {
	.js {
		@import '~aos/src/sass/animations';
	}
}