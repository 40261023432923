﻿$breakpoints: ( "ms": 320px, "mm": 375px, "ml": 432px, "ts": 640px, "t": 768px, "l": 1024px, "lm": 1280px, "ll": 1440px, "ds": 1680px, "d": 1920px, "4k": 2560px);

@mixin breakpoint($breakpoint-name) {
	$pixels: map-get($breakpoints, $breakpoint-name);

	@media only screen and (min-width: $pixels) {
		@content;
	}
}

@mixin breakpoint-max($breakpoint-name) {
	$pixels: map-get($breakpoints, $breakpoint-name);

	@media only screen and (max-width: ($pixels - 1px)) {
		@content;
	}
}

@mixin breakpoint-min-max($breakpoint-min,$breakpoint-max) {
	$pixels-min: map-get($breakpoints, $breakpoint-min);
	$pixels-max: map-get($breakpoints, $breakpoint-max);

	@media only screen and (min-width: $pixels-min) and (max-width: ($pixels-max - 1px)) {
		@content;
	}
}
