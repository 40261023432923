﻿.pulse {
	position: relative;
	text-decoration: none;

	&:before,
	&:after {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
	}

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 100%;
		box-shadow: 0 0 0 rgba($color-red, 0.75);
		transition: width 250ms ease-in-out, height 250ms ease-in-out, background-color 250ms ease-in-out;
	}

	&:after {
		color: #fff;
		font-size: 1.25rem;
	}

	@media(hover: hover) and (pointer: fine) {
		&:hover {
			&:before {
				animation: pulse 1500ms infinite;
			}
		}
	}

	.is-active & {
		&:before {
			background: $color-red;
			animation: none;
		}
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba($color-red, 0.75);
	}

	70% {
		-webkit-box-shadow: 0 0 0 1.111rem rgba(204,169,44, 0);
	}

	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba($color-red, 0.75);
		box-shadow: 0 0 0 0 rgba($color-red, 0.75);
	}

	70% {
		-moz-box-shadow: 0 0 0 1.111rem rgba(204,169,44, 0);
		box-shadow: 0 0 0 1.111rem rgba(204,169,44, 0);
	}

	100% {
		-moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
		box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
}

@keyframes revealProductHeading {
	0% {
		transform: translateX(5%);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes revealProductHeadingInversed {
	0% {
		transform: translateX(-5%);
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes scaleDown {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.1);
	}
}

@keyframes full-rotation {
	0% {
		transform: rotate(0deg);
		transform-origin: center center;
	}

	100% {
		transform: rotate(360deg);
		transform-origin: center center;
	}
}

.rotate-img {
	animation: full-rotation 1s forwards;
}

@keyframes squeeze {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.25);
	}

	100% {
		transform: scale(1); 
	}
}

.squeeze-img {
	animation: squeeze 1s forwards;
}

@keyframes wobble {
	0% {
		transform: rotate(0deg);
	}

	25% {
		transform: rotate(8deg);
	}

	75% {
		transform: rotate(-8deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

.wobble-img {
	animation: wobble .75s ease forwards;
}