﻿.button {
	@include font-button;
	position: relative;
	user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-webkit-tap-highlight-color: transparent;
	outline: none;
	z-index: 0;
	display: inline-block;
	padding: rem(18px) rem(64px) rem(18px) rem(40px);
	margin: rem(16px) 0 0 0;
	overflow: hidden;
	cursor: pointer;
	border: none;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	box-shadow: none !important;
	width: max-content;
	background: transparent;
	background-size: 200% 100%;
	transition: background-position .25s ease;

	@media(hover: hover) and (pointer: fine) {
		&:hover {
			background-position: -100% 0;
		}
	}

	&:after {
		@include icon($icon-arrow-right);
		position: absolute;
		font-size: rem(16px);
		font-weight: 600;
		right: rem(32px);
		top: 51%;
		transform: translate(-50%, -50%);
	}

	&::selection, &::-moz-selection {
		color: none;
		background: none;
	}

	&:focus {
		outline: 0 !important;
	}

	&.is-loading {
		padding-left: 60px;

		&:after {
			position: absolute;
			display: block;
			content: '';
			border: rem(4px) solid $color-default;
			border-radius: 50%;
			border-top: rem(4px) solid $color-darkgreen;
			width: rem(20px);
			height: rem(20px);
			top: 50%;
			left: rem(24px);
			margin-top: -10px;
			animation: spin 1.5s linear infinite;
			z-index: 2;
		}
	}
}

.single-button {
	margin-top: unset;
	margin-right: unset;
}

.buttons {
	margin-top: rem(-16px);
	padding-bottom: rem(40px);
	margin-right: rem(-24px);

	.button {
		margin-right: rem(24px);
	}
}
