﻿footer {
	position: relative;
	padding-bottom: rem(173px);
	background: #003030;

	&:before {
		position: absolute;
		content: "";
		width: 100%;
		height: rem(173px);
		left: 0;
		bottom: 0;
		z-index: 0;
		background: url(../../images/pattern-darkgreen.svg);
	}

	.footer-newsletter {
		@include breakpoint(l) {
			.column:first-child {
				width: 42%;
			}

			.column:last-child {
				width: 58%;
			}

			.container {
				padding-top: rem(116px);
				padding-bottom: rem(120px);
			}
		}

		.column:first-child {
			position: relative;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				content: "";
				width: rem(261px);
				height: rem(238px);
				background-size: 100%;
				background: url(../../images/eye.svg) no-repeat;
				opacity: 0;
				transition: all .15s ease-in-out;

				@include breakpoint(l) {
					margin-left: 24px;
					opacity: 1;
					transition: all .15s ease-in-out;
				}
			}
		}

		.column:last-child {
			h2 {
				position: relative;
				font-size: rem(48px);
				line-height: rem(48px);
				padding-bottom: rem(22px);
				margin-bottom: rem(28px);
				text-align: left;

				&:after {
					position: absolute;
					content: "";
					bottom: 0;
					left: 0;
					background: $color-red;
					width: rem(100px);
					height: rem(4px);
				}
			}

			p:last-child {
				margin-bottom: rem(32px);
			}

			.mailchimp-form {

				.mc-field-group {
					display: flex;
					flex-wrap: wrap;
					margin-bottom: rem(-32px);

					@include breakpoint(l) {
						flex-wrap: unset;
					}

					.email {
						width: rem(423px);
						height: rem(56px);
						background-color: $color-beige;
						border-radius: rem(4px);
						padding-left: rem(16px);
						border: none;
						color: $color-darkgreen;
						font-size: rem(18px);
						font-weight: 500;
						margin: 0 rem(48px) rem(32px) 0;
					}

					.button {
						padding: unset;
						margin-bottom: rem(32px);

						input {
							cursor: pointer;
							width: 100%;
							height: 100%;
							display: block;
							padding: 1.125rem 4rem 1.125rem 2.5rem;
							background-color: transparent;
							padding: rem(18px) rem(64px) rem(18px) rem(40px);
							border: none;
						}
					}
				}

				label, .indicates-required {
					display: none;
				}

				input::placeholder, textarea::placeholder {
					color: $color-darkgreen;
					opacity: .5;
					font-size: rem(18px);
					font-weight: 500;
				}

				.brandingLogo {
					width: 28.5%;
					margin-top: 32px;
				}
			}
		}
	}

	.footer-block {
		position: relative;

		.footer-nav {
			margin-bottom: rem(-32px);

			> li {
				margin-bottom: rem(32px);
			}
		}

		&:after {
			position: absolute;
			content: "";
			opacity: .1;
			top: 0;
			left: 0;
			background: $color-default;
			width: 100%;
			height: 1px;
		}

		@include breakpoint(lm) {
			.container {
				padding-top: rem(116px);
				padding-bottom: rem(120px);
			}
		}

		.column:first-child {
			@include breakpoint(lm) {
				width: 69%;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					width: 50%;

					@include breakpoint(lm) {
						width: 20%;
					}

					a {
						@include font-footer-link-heading;
						display: block;
						margin-bottom: rem(16px);
						@include underline;
					}

					ul {
						@include breakpoint-max(lm) {
							margin-bottom: rem(32px);
						}
					}

					ul, li {
						width: 100%;

						a {
							@include font-footer-link;
							margin-bottom: rem(20px);
						}
					}

					li:last-child {
						a {
							margin-bottom: unset !important;
						}
					}
				}
			}
		}

		.column:last-child {
			position: relative;
			height: rem(375px);

			@include breakpoint(lm) {
				width: 27%;
				height: auto;
			}

			.slogan {
				@include font-footer-slogan;
				position: relative;
				left: 50%;
				top: 55%;
				transform: translate(-50%, -50%);
				text-align: center;

				@include breakpoint(lm) {
					position: absolute;
					top: 52%;
					left: 60%;
				}

				&:before {
					position: absolute;
					content: "";
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					background: url(../../images/sun.svg) no-repeat;
					width: rem(350px);
					height: rem(350px);

					@include breakpoint(lm) {
						width: rem(375px);
						height: rem(375px);
					}

					z-index: -1;
					pointer-events: none;
				}

				span {
					display: block;
					z-index: 1;
				}
			}
		}
	}

	.footer-links {
		@include font-footer-legal;
		z-index: 1;

		ul {
			display: flex;
			flex-wrap: wrap;
			padding-top: 0;
			padding-bottom: 0;

			li {
				padding: 0 0 rem(20px) 0;
				margin-right: rem(32px);

				a {
					@include underline;
				}
			}
		}
	}
}
