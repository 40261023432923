﻿#menu {
	display: none;

	&:checked {
		~ .menu {
			transform: translateY(0);
			transition: all 0.25s ease-out;

			.navigation > li a {
				transition: transform .4s ease-in-out, opacity .45s ease-in-out;
				opacity: 1;
				transform: translateY(0);
			}
		}

		~ .hamburger {
			background: rgba($color-beige, 1);
			border: rgba($color-beige, .75);
			transition: all .15s ease;

			.hamburger-box {
				background: rgba($color-darkred, 0);
				transition: all .15s ease;

				&:before {
					background: $color-darkred;
					transition: all .15s ease;
					transform: translate(-50%,-50%) rotate(-45deg);
					margin-top: unset;
				}

				&:after {
					background: $color-darkred;
					transition: all .15s ease;
					transform: translate(-50%,-50%) rotate(45deg);
					margin-top: unset;
				}
			}
		}
	}
}

.menu {
	position: fixed;
	width: 100%;
	height: 100%;
	background: $color-darkred;
	transform: translateX(110%);
	transition: all 0.25s ease-in;
	display: flex;
	align-items: center;

	@include breakpoint(l) {
		display: none;
	}

	.navigation {
		position: absolute;
		top: 30%;
		width: 100%;
		padding: 0 10%;
		overflow: scroll;
		max-height: calc(80vh - 30%);

		li {
			position: relative;
			font-size: rem(24px);
			font-weight: 700;
			line-height: rem(24px);
			margin-bottom: rem(32px);
			padding-right: rem(32px);
			width: 100%;
			overflow: hidden;

			&.active {
				> a {
					color: $color-red;
				}
			}

			input[type=checkbox] {
				display: none;

				&:checked {
					~ dl dd {
						max-height: 9999px;
						transition: max-height 1s ease-in-out;

						li {
							opacity: 1;
							transition: all .15s ease;
						}
					}

					~ label {
						&:after {
							transform: rotate(180deg) !important;
							transform-origin: center center;
							transition: all .15s ease;
						}
					}
				}
			}

			label {
				position: absolute;
				margin-top: 1px;
				margin-left: 100%;
				top: 0;
				right: 0;
				width: rem(24px);
				height: rem(24px);

				span {
					display: none;
				}

				&:after {
					@include icon($icon-arrow-down);
					position: absolute;
					display: block;
					color: $color-beige;
					font-size: rem(24px);
					font-weight: 600;
					right: 0;
					top: 0;
					transform-origin: center center;
					transition: all .15s ease;
				}
			}

			a {
				display: block;
				transform: translateY(60%);
				opacity: 0;
				transition: all .5s ease-out;
			}
		}
		//dropdowns
		dd {
			max-height: 0;
			transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
			overflow: hidden;
		}

		ul {
			margin-top: rem(32px);

			li {
				padding-left: rem(8px);
				font-size: rem(20px);
				font-weight: 500;
				opacity: 0;
				transition: .15s ease;

				&:last-child {
					margin-bottom: unset;
				}
			}
		}
	}
}