﻿.hamburger {
	position: fixed;
	display: flex;
	width: rem(64px);
	height: rem(64px);
	border-radius: 50%;
	bottom: rem(32px);
	left: 50%;
	transform: translate(-50%,-50%);
	background: rgba($color-darkred, 1);
	border: 1px solid darken(rgba($color-darkred, 1), 5%);
	z-index: 99;
	transition: all .15s ease;

	@include breakpoint(ll) {
		display: none;
	}

	.hamburger-box {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 2px;
		width: 50%;
		background: $color-beige;
		content: "";
		transform: translate(-50%,-50%);
		transition: all .15s ease;

		&:before, &:after {
			position: absolute;
			top: 50%;
			left: 50%;
			height: 2px;
			width: 70%;
			background: $color-beige;
			content: "";
			transform: translate(-50%,-50%);
			transition: all .15s ease;
		}

		&:before {
			margin-top: -7px;
		}

		&:after {
			margin-top: 7px;
		}
	}
}
