﻿@import "~lightgallery.js/dist/css/lightgallery.css";

.lg-backdrop {
	background: #000;
	padding: 25px;
}

.lg-backdrop.in {
	opacity: 0.8;
}

.lg-video-cont {
	@include breakpoint(l) {
		width: 75% !important;
	}
}
