﻿.languages {
	position: relative;
	width: rem(24px);
	height: 100%;
	margin-left: auto;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);

	@include breakpoint(l) {
		margin-left: unset;
	}

	label {
		cursor: pointer;

		span {
			display: none;
		}

		&:after {
			@include icon($icon-globe);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: rem(24px);
			color: $color-beige;
			transition: color .15s ease;
		}

		@media(hover: hover) and (pointer: fine) {
			&:hover {
				&:after {
					transition: color .15s ease;
					color: $color-red;
				}
			}
		}
	}

	input[type=checkbox] {
		display: none;

		&:checked {
			~ dl dd {
				max-height: 9999px;
				transition: max-height 1s ease-in-out;
			}
		}
	}

	dd {
		max-height: 0;
		transition: max-height 0.25s cubic-bezier(0, 1, 0, 1);
		overflow: hidden;
		position: absolute;
		z-index: -1;
		margin-left: rem(-20px);
	}

	ul {
		margin-top: rem(44px);
		padding: rem(20px);
		background: $color-darkgreen;
		border-radius: rem(12px);

		li {
			font-size: rem(18px);
			font-weight: 500;
			margin-bottom: rem(8px);

			a {
				transition: color .15s ease;
				text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
			}

			@media(hover: hover) and (pointer: fine) {
				&:hover {
					a {
						transition: color .15s ease;
						color: $color-red;
					}
				}
			}

			&:last-child {
				margin-bottom: unset;
			}
		}
	}
}
