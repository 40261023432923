﻿@font-face {
	font-family: 'Campton';
	src: url('../../fonts/Campton-Light.woff2') format('woff2'), url('../../fonts/Campton-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Campton';
	src: url('../../fonts/Campton-Medium.woff2') format('woff2'), url('../../fonts/Campton-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Campton';
	src: url('../../fonts/Campton-SemiBold.woff2') format('woff2'), url('../../fonts/Campton-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Isabel';
	src: url('../../fonts/Isabel-Regular.woff2') format('woff2'), url('../../fonts/Isabel-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Isabel';
	src: url('../../fonts/Isabel-Italic.woff2') format('woff2'), url('../../fonts/Isabel-Italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-stretch: normal;
	font-display: block;
}

@mixin font-text {
	font-family: $font-campton;
	font-size: rem(16px);
	line-height: rem(24px);
	font-weight: 300;
	color: $color-beige;
}

@mixin font-caption {
	font-family: $font-isabel;
	font-size: rem(24px);
	line-height: rem(24px);
	font-weight: 400;
	font-style: italic;
}

@mixin font-subtitle {
	font-family: $font-isabel;
	font-size: rem(24px);
	line-height: rem(24px);
	font-weight: 400;
	font-style: italic;
}

@mixin font-inspiration {
	font-family: $font-isabel;
	font-size: rem(20px);
	line-height: rem(32px);
	font-weight: 400;
	font-style: italic;
}

@mixin font-alternating-inspiration-paragraph {
	font-family: $font-isabel;
	font-size: rem(24px);
	line-height: rem(32px);
	font-weight: 400;
	font-style: italic;
	color: #999999;
}

@mixin font-heading-inspiration {
	font-family: $font-campton;
	font-size: rem(56px);
	line-height: rem(56px);
	font-weight: 600;
	font-style: normal;
	color: $color-beige;

	@include breakpoint(l) {
		font-size: rem(72px);
		line-height: rem(72px);
	}
}

@mixin font-button {
	font-size: rem(18px);
	line-height: rem(20px);
	font-weight: 500;
}

@mixin font-footer-link-heading {
	font-size: rem(24px);
	line-height: rem(32px);
	font-weight: 600;
}

@mixin font-footer-link {
	font-size: rem(16px);
	line-height: rem(20px);
	font-weight: 300;
}

@mixin font-footer-legal{
	font-size: rem(14px);
	line-height: rem(16px);
	font-weight: 300;
}

@mixin font-footer-slogan {
	font-family: $font-isabel;
	font-size: rem(48px);
	line-height: rem(48px);
	font-weight: 400;
	font-style: italic;
}

@mixin font-product-home {
	font-size: rem(72px);
	line-height: rem(72px);
	font-weight: 600;
	font-style: normal;
	color: $color-darkgreen;
}

@mixin font-intro-home {
	font-family: $font-isabel;
	font-size: rem(20px);
	line-height: rem(32px);
	font-weight: 400;
	font-style: italic;
}

@mixin font-product-home-text {
	font-family: $font-isabel;
	font-size: rem(24px);
	line-height: rem(32px);
	font-weight: 400;
	font-style: italic;
	color: #999999;
}