﻿.header {
	overflow: hidden;

	&.no-media {
		min-height: rem(480px);
		height: rem(80vh);
	}

	figure {
		min-height: rem(480px);
		max-height: 100vh;

		@media screen and (max-width: 1023px) {
			min-height: rem(600px);
		}
	}

	.heading {
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 1;
		width: 90vw;
		text-align: center;
		transition: all .15s ease;
		transform: translate(-50%, -50%);

		h1 {
			text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

			span {
				opacity: 0;
				display: block;
			}

			span:first-child {
				transform: translateX(5%);
				animation: revealProductHeading 3s ease-out forwards;
			}

			span:nth-child(2) {
				transform: translateX(-5%);
				animation: revealProductHeadingInversed 3s ease-out forwards;
			}
		}
	}

	&.headertext {
		img {
			animation: scaleDown 17s linear forwards;
		}
	}
}

.home .header {

	figure {
		min-height: 100vh;
	}

	.heading {
		h1 {
			width: fit-content;
			margin: 0 auto;
			text-align: left;
		}
	}

	.intro-media {
		img {
			animation: scaleDown 17s linear forwards;
		}
	}

	.transition-media {
		position: absolute;
		top: 0;
		width: 100%;
		opacity: 0;
		transform: scale(1.1);
	}
}

.product-intro-container {
	width: 100%;
	height: 100vh;
	position: relative;
	overflow: hidden;
	margin-top: -1px;

	.product-header {
		pointer-events: none;
		z-index: 1;
	}

	.product-header, .product-header figure {
		height: 100%;
	}

	.product-intro {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;

		.container {
			opacity: 0;

			@include breakpoint(l) {
				.column {
					p:last-child {
						margin-bottom: unset;
					}
				}
			}
		}
	}
}
