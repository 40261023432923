﻿@import '~swiper/swiper';
@import '~swiper/components/navigation/navigation';
@import '~swiper/components/effect-fade/effect-fade';

//Swiper
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
	opacity: 1;

	background: rgba($color-beige, .75);

	&:after {
		color: rgba($color-darkgreen, .75);
		//color: lighten($color-darkgreen, 15%) !important;
	}
}

.swiper-button-prev, .swiper-button-next {
	position: absolute;
	content: "";
	top: 50%;
	transform: translateY(-50%);
	width: 56px;
	height: 56px;
	background: $color-beige;

	&:after {
		position: absolute;
		display: block;
		color: $color-darkgreen;
		font-size: rem(24px);
		font-weight: 600;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		transform-origin: center center;
		transition: all .15s ease;
	}
}

.swiper-button-prev {
	left: 0;

	&:after {
		@include icon($icon-arrow-left);
	}
}

.swiper-button-next {
	right: 0;

	&:after {
		@include icon($icon-arrow-right);
	}
}

.swiper-container {
	position: relative;
}

.swiper-pagination {
	position: absolute;
	top: rem(110px);
	right: rem(24px);
	transform: translateY(-50%);
	z-index: 2;

	@include breakpoint(l) {
		top: 50%;
		right: unset;
		left: rem(24px);
	}

	.swiper-pagination-bullet {
		display: block;
		width: rem(16px);
		height: rem(16px);
		border-radius: 50%;
		margin-bottom: rem(16px);
		opacity: .2;
		cursor: pointer;

		&.swiper-pagination-bullet-active {
			opacity: 1;
		}
	}

	.swiper-pagination-bullet:only-child {
		visibility: hidden;
	}
}

.swiper-wrapper {
	-webkit-transition-timing-function: linear !important;
	-o-transition-timing-function: linear !important;
	transition-timing-function: linear !important;
}