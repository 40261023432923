﻿@import "fonts";
@import "icon";

// Primary colors
$color-default: #ffffff;
$color-darkgreen: #003030;
$color-pink: #ffbe9f;
$color-lightpink: #f9d1bd;
$color-beige: #fdeee7;
$color-darkred: #783031;
$color-red: #e5554f;
$color-black: #0f0d0e;

// Base
$font-campton: "Campton", sans-serif;
$font-isabel: "Isabel", sans-serif;
$default-line-height: rem(32px);

// Container
$container-max-width: rem(1680px);
//$container-scroll-max-width: rem(676px);
$container-smallest-max-width: rem(768px);
$container-small-max-width: rem(912px);
$container-med-max-width: rem(1152px);
$container-inspiration-home-max-width: rem(1304px);
$container-large-max-width: rem(1392px);
$container-largest-max-width: rem(1680px);

// Grid
$grid-columns: 12;
$grid-gap: 3rem;
