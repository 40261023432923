﻿@use "sass:math";
%container,
.container {
	max-width: ($container-max-width + $grid-gap);
	padding: rem(74px) math.div($grid-gap, 2) rem(74px);
	margin: 0 auto;

	&.fluid {
		max-width: none;
		margin: 0;
	}

	&.scroll {
		max-width: calc(35.2vw + 48px);
		//max-width: calc($container-scroll-max-width + $grid-gap);
	}

	&.cta-size {
		max-width: (rem(704px) + $grid-gap);
	}

	&.smallest {
		max-width: (rem(768px) + $grid-gap);
	}

	&.small {
		max-width: (rem(912px) + $grid-gap);
	}

	&.med {
		max-width: (rem(1152px) + $grid-gap);
	}

	&.inspiration-home {
		max-width: (rem(1304px) + $grid-gap);
	}

	&.large {
		max-width: (rem(1392px) + $grid-gap);
	}

	&.largest {
		max-width: (rem(1680px) + $grid-gap);
	}
}
